<script setup lang="ts">

</script>

<template>
  <div class="relative h-150 w-full pb-[33.3333%] transition-all duration-500 ease-in-out md:h-64">
    <div class="absolute inset-0 h-full w-full">
      <div class="relative h-full w-full">
        <div
          class="absolute top-0 h-full w-full flex flex-col justify-between overflow-hidden bg-cover px-4 bgi-[webcontent/homepage/huglo_bckg.png] md:px-8"
        >
          <div class="h-full w-full flex flex-col items-center text-center md:flex-row">
            <div class="h-full w-full flex flex-col items-center justify-center md:basis-1/2">
              <h1
                class="text-[4rem] text-white font-extrabold uppercase"
              >
                Rabaty nawet<br> <span>do 90%</span>
              </h1>
            </div>
            <div class="hidden h-full w-full flex-col items-center justify-end md:flex md:basis-1/2">
              <img
                src="/webcontent/homepage/huglo_pl_weron.png" alt="Huglo" class="w-150 -m-20"
              >
            </div>
          </div>
          <div>
            <div class="flex flex-col items-center self-end justify-center flex-gap-4 pb-4 sm:flex-row" /><!-- [ -->
            <div
              class="hidden flex-col items-center gap-y-2 bg-black/40 px-10 py-3 color-white -m-x-10 lg:flex lg:flex-row lg:justify-center lg:flex-gap-[15%]"
            >
              <div class="flex font-bold" icon-class="w-4 h-4">
                <div class="w-full">
                  <div class="flex items-center gap-x-1 sm:gap-x-3">
                    <NIcon class="i-ph:gift h-6 w-6 flex-none n-icon" />
                    <span class="block text-xs sm:text-sm">Prezent do każdego zamówienia</span>
                  </div>
                </div>
              </div>
              <div class="flex font-bold" icon-class="w-4 h-4">
                <div class="w-full">
                  <div class="flex items-center gap-x-1 sm:gap-x-3">
                    <NIcon class="i-ph:package h-6 w-6 flex-none n-icon" />
                    <span class="block text-xs sm:text-sm"> Ekspresowa wysyłka w ciągu 24 godzin</span>
                  </div>
                </div>
              </div>
              <div class="flex font-bold" icon-class="w-4 h-4">
                <div class="w-full">
                  <div class="flex items-center gap-x-1 sm:gap-x-3">
                    <NIcon class="i-ph:truck h-6 w-6 flex-none n-icon" />
                    <span class="block text-xs sm:text-sm">Szybka wysyłka</span>
                  </div>
                </div>
              </div>
              <div class="flex font-bold" icon-class="w-4 h-4">
                <div class="w-full">
                  <div class="flex items-center gap-x-1 sm:gap-x-3">
                    <NIcon class="i-ph:arrows-clockwise h-6 w-6 flex-none n-icon" />
                    <span class="block text-xs sm:text-sm">Zwrot towaru w ciągu 14 dni</span>
                  </div>
                </div>
              </div>
            </div><!-- ] -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
