<script setup lang="ts">
import HPBanner from '~/components/templates/HPBanner.vue'

const { t } = useI18n()

const total = ref(0)
const isLoading = ref(false)

const bannerData = {
  items: [
    {
      link: '/collections/4921/damske-obleceni-a-doplnky',
      text: t('general.homepage_navigation.womens'),
      background: 'bg-purple-300',
      imageLink: '/webcontent/homepage/womens.png',
    },
    {
      link: '/collections/4725/panske-obleceni-a-doplnky',
      text: t('general.homepage_navigation.mens'),
      background: 'bg-blue-400',
      imageLink: '/webcontent/homepage/mens.png',
    },
    {
      link: '/collections/5117/detske-obleceni-a-doplnky',
      text: t('general.homepage_navigation.home'),
      background: 'bg-cyan-200',
      imageLink: '/webcontent/homepage/home.png',
    },
    {
      link: '/collections/5117/detske-obleceni-a-doplnky',
      text: t('general.homepage_navigation.kids'),
      background: 'bg-gray-200',
      imageLink: '/webcontent/homepage/kids.png',
    },

  ],
}
useHead({
  title: computed(() => t('layouts.title', [t('pages.title.index')])),
})
</script>

<template>
  <div class="w-full">
    <HPBanner />
  </div>

  <div class="pb-8 pt-6">
    <div class="mx-auto max-w-3xl px-4 lg:max-w-[88rem] md:max-w-4xl lg:px9 sm:px-6 sm:px4">
      <div class="mb4">
        <ProductsGrid
          @total="(v) => (total = v)"
          @loading="(loading) => (isLoading = loading)"
        />
      </div>
    </div>
  </div>
</template>
